<!--
 * @Author: qy
 * @LastEditors: qy
 * @description: page description
 * @Date: 2020-07-29 18:49:00
 * @LastEditTime: 2020-07-29 19:48:50
--> 
<template>
  <div class="help-content-item" v-if="item">
    <div class="item-title h c" :class="{expand: expand, selected: item.id === current}" @click="handleItemClick">
      <i :class="[{hasChild: item.children && item.children.length > 0}, expand ? 'el-icon-caret-bottom' : 'el-icon-caret-right', 'arrow']" @click.stop="expand = !expand"></i>
      <span>{{item.label}}</span>
    </div>
    <template v-if="item.children && item.children.length">
      <transition name="slide">
        <div class="item-children" v-show="expand">
          <help-content-item v-for="o in item.children" :key="o.id" :item="o" :current="current" @select="handleSelect" />
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
export default {
  name: "helpContentItem",
  componentName: "helpContentItem",
  props: {
    item: Object,
    current: null,
  },
  data() {
    return {
      expand: true,
    };
  },
  methods: {
    handleItemClick() {
      if (this.item.url) this.$emit("select", this.item);
      else this.expand = !this.expand;
    },
    handleSelect(_item) {
      this.$emit("select", _item);
    },
  },
};
</script>

<style lang="less">
.help-content-item {
  font-size: 14px;
  cursor: pointer;
  .item-title {
    padding: 6px 20px;
    .arrow {
      display: block;
      width: 12px;
      font-size: 12px;
      margin-right: 8px;
      opacity: 0;
      cursor: default;
      &.hasChild {
        opacity: 0.3;
        transition: all 0.3s;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    &:hover {
      color: #409eff;
    }

    &.selected {
      color: #409eff;
      font-weight: bold;
      cursor: default;
    }
  }

  .item-children {
    padding-left: 28px;
  }
}
</style>